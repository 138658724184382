import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import './landing.scss'

const LandingPage = () => {
	const data = useStaticQuery(graphql`
		query LandingBanners {
			promise_2022:file(relativePath: {eq: "landing-banners/promise-2022.jpg"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			}
			january_promise:file(relativePath: {eq: "landing-banners/2022-january-promise.png"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			}
			february_promise:file(relativePath: {eq: "landing-banners/2022-february-promise.png"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			}
			march_promise:file(relativePath: {eq: "landing-banners/2022-march-promise.png"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			}
			april_promise:file(relativePath: {eq: "landing-banners/2022-april-promise.png"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			}
			may_promise:file(relativePath: {eq: "landing-banners/2022-may-promise.png"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			}
			june_promise:file(relativePath: {eq: "landing-banners/2022-june-promise.png"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			}
			july_promise:file(relativePath: {eq: "landing-banners/2022-july-promise.png"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			}
			august_promise:file(relativePath: {eq: "landing-banners/2022-august-promise.png"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			}
			september_promise:file(relativePath: {eq: "landing-banners/2022-september-promise.png"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			}
			october_promise:file(relativePath: {eq: "landing-banners/2022-october-promise.png"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			}
		}	  
	`)
	return (
		<section className='landing-layout'>
			<div id="landingCarousel" className="carousel slide" data-bs-ride="carousel">
				<div className="carousel-inner">
					<div className="carousel-item active bg-dark">
						<div className="backdrop">
							<Img fluid={data.promise_2022.childImageSharp.fluid} className="d-block" />
						</div>
						<div className="content">
							<Img fluid={data.promise_2022.childImageSharp.fluid} className="d-block" />
						</div>
					</div>
					<div className="carousel-item">
						<div className="backdrop">
							<Img fluid={data.october_promise.childImageSharp.fluid} className="d-block" />
						</div>
						<div className="content">
							<Img fluid={data.october_promise.childImageSharp.fluid} className="d-block" />
						</div>
					</div>
					<div className="carousel-item">
						<div className="backdrop">
							<Img fluid={data.september_promise.childImageSharp.fluid} className="d-block" />
						</div>
						<div className="content">
							<Img fluid={data.september_promise.childImageSharp.fluid} className="d-block" />
						</div>
					</div>
					<div className="carousel-item">
						<div className="backdrop">
							<Img fluid={data.august_promise.childImageSharp.fluid} className="d-block" />
						</div>
						<div className="content">
							<Img fluid={data.august_promise.childImageSharp.fluid} className="d-block" />
						</div>
					</div>
					<div className="carousel-item">
						<div className="backdrop">
							<Img fluid={data.july_promise.childImageSharp.fluid} className="d-block" />
						</div>
						<div className="content">
							<Img fluid={data.july_promise.childImageSharp.fluid} className="d-block" />
						</div>
					</div>
					<div className="carousel-item">
						<div className="backdrop">
							<Img fluid={data.june_promise.childImageSharp.fluid} className="d-block" />
						</div>
						<div className="content">
							<Img fluid={data.june_promise.childImageSharp.fluid} className="d-block" />
						</div>
					</div>
					<div className="carousel-item">
						<div className="backdrop">
							<Img fluid={data.may_promise.childImageSharp.fluid} className="d-block" />
						</div>
						<div className="content">
							<Img fluid={data.may_promise.childImageSharp.fluid} className="d-block" />
						</div>
					</div>
					<div className="carousel-item">
						<div className="backdrop">
							<Img fluid={data.april_promise.childImageSharp.fluid} className="d-block" />
						</div>
						<div className="content">
							<Img fluid={data.april_promise.childImageSharp.fluid} className="d-block" />
						</div>
					</div>
					<div className="carousel-item">
						<div className="backdrop">
							<Img fluid={data.march_promise.childImageSharp.fluid} className="d-block" />
						</div>
						<div className="content">
							<Img fluid={data.march_promise.childImageSharp.fluid} className="d-block" />
						</div>
					</div>
					<div className="carousel-item">
						<div className="backdrop">
							<Img fluid={data.february_promise.childImageSharp.fluid} className="d-block" />
						</div>
						<div className="content">
							<Img fluid={data.february_promise.childImageSharp.fluid} className="d-block" />
						</div>
					</div>
					<div className="carousel-item">
						<div className="backdrop">
							<Img fluid={data.january_promise.childImageSharp.fluid} className="d-block" />
						</div>
						<div className="content">
							<Img fluid={data.january_promise.childImageSharp.fluid} className="d-block" />
						</div>
					</div>
				</div>
				<button className="carousel-control-prev" type="button" data-bs-target="#landingCarousel" data-bs-slide="prev">
					<i className="fas fa-circle-chevron-left"></i>
					<span className="visually-hidden">Previous</span>
				</button>
				<button className="carousel-control-next" type="button" data-bs-target="#landingCarousel" data-bs-slide="next">
					{/* <span className="carousel-control-next-icon" aria-hidden="false"></span> */}
					<i className="fas fa-circle-chevron-right"></i>
					<span className="visually-hidden">Next</span>
				</button>
			</div>
		</section>
	)
}
export default LandingPage