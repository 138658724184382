import React from "react"

import MainLayout from "../layouts/mainlayout/MainLayout"
import LandingPage from "../components/landing/LandingPage"
import Announcements from "../components/announcements/Announcements"
// import Events from "../components/events/Events"
// import QuickLinks from "../components/quicklinks/QuickLinks"
import WelcomeNote from "../components/welcome/WelcomeNote"

function Home() {
  return (
    <MainLayout title="Home">
      <LandingPage />
      {/* <Announcements /> */}
      {/* <Events /> */}
      {/* <QuickLinks /> */}
      <WelcomeNote />
    </MainLayout>
  )
}

export default Home