import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"

import './welcome.scss';

const WelcomeNote = () => {
	const data = useStaticQuery(graphql`
		query FamilyPic {
			file(relativePath: {eq: "family-pic.jpg"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			}
		}	  
	`)
	return (
		<section className='welcome-layout'>
			<div className="family-pic">
				<Img fluid={data.file.childImageSharp.fluid} />
			</div>
			<div className="welcome-note">
				<h3 className="section-head mb-4">Welcome to the Full Life Family!</h3>
				<div className="note">
					&nbsp; &nbsp; &nbsp; We'd like to take this opportunity to give a heartfelt welcome to the Full Life Centre Assembly of God Church (FLAG). We're glad to have you here,  whether you're just looking around or looking for a place to worship.
					<br />
					&nbsp; &nbsp; &nbsp; FLAG is committed to delivering Full Life to all of Thiruverkadu through Full Gospel. We are passionate about assisting individuals in experiencing a deeper revelation of God in their life, growing in Biblical principles, strengthening families and marriages, and spreading the love and Gospel of Jesus Christ. <br />
					&nbsp; &nbsp; &nbsp; We encourage you to join us as we seek to follow God's heart and will, grow as a community, fulfil God's calling, and share Jesus' love with everyone we meet and in every part of society. <br />
					&nbsp; &nbsp; &nbsp; God is doing some very exciting things in the hearts and lives of those who call Full Life centre as their Home. So, come and check out FLAG! Who knows? It could make a difference in you life too!
				</div>
			</div>
		</section>
	)
}
export default WelcomeNote